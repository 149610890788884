@import './_variables';

.footer {
  min-height: 126px;
  background-color: $main-color;

  &__container {
    display:flex;
    justify-content: center;
    align-items: center;
    min-height:126px;

    @media (max-width: 500px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  
  }

  ul{
    display:flex;
    justify-content: center;
    align-items: center;
    min-height:126px;
    list-style-type:none;
    padding-inline-start: 0px;

    @media (max-width: 500px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  ul li{
    padding: 0 15px;


    @media (max-width: 500px) {
      padding: 10px 0;
    }
  }

  ul li a{
    color: $footer-link-color;
    &:hover,
    :active {
      color: $main-active-color;
    }
  }

}
