@import './variables';

.header {
  position: fixed;
  top: 0;
  z-index: 15000;
  min-height: 90px;
  width: 100%;
  background-color: #fff;

  &__top-menu {
    position: relative;
    z-index: 15;
  }

  &__desktop-site-nav {
    &-container {
      padding: 46px;
      min-height: 400px;

      position: fixed;
      width: 100%;
      top: -120%;
      left: 0;
      right: 0;
      z-index: 14;

      background-color: #eef5f4;

      transition: all 0.3s linear;

      &.active {
        top: 90px;
      }
    }

    &-item {
      margin-top: 40px;
      margin-right: 100px;
    }
    &-icon {
      max-width: 19px;
      margin-right: 20px;
    }
  }

  &__nav-menu-btn {
    height: 13px;
    width: 32px;
    display: inline-flex;
    flex-direction: column;
    position: relative;
    transition: all 0.3s linear;

    // border-top: 2px solid $main-color;
    // border-bottom: 2px solid $main-color;

    &::before,
    &::after {
      content: ' ';
      clear: both;
      display: block;

      width: 32px;
      height: 2px;

      position: absolute;
      background-color: $main-color;

      transform: rotateZ(0deg);
      transform-origin: left;

      transition: all 0.3s linear;
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }

    &.active {
      height: 32px;
      transition: all 0.3s linear;

      &::before {
        transform: rotateZ(45deg);
        width: 42.25px;
        transition: all 0.3s linear;
      }
      &::after {
        transform: rotateZ(-45deg);
        width: 42.25px;
        transition: all 0.3s linear;
      }
    }
  }

  &__mobile-nav,
  &__mobile-nav-inner {
    position: fixed;
    top: 60px;
    bottom: 0px;
    left: -100%;

    padding-top: 90px;
    padding-bottom: 40px;
    padding-left: 115px;

    width: 100%;

    overflow-y: auto;

    background-color: #fff;
    transition: all 0.3s linear;

    &-item {
      display: flex;
      align-items: center;
      position: relative;

      &:not(:last-of-type) {
        margin-bottom: 35px;
      }
    }
  }

  &__mobile-nav {
    &.active {
      left: 0;
      z-index: 1;

      ul {
        margin-bottom:0;
        align-items: flex-start;
        justify-content:flex-start;            
        
        :after{
          display:none!important;
        }


        li {
          position: relative;
          display: flex;
          flex-direction:column;
          align-items: flex-start;
          justify-content:flex-start;
          margin: 40px 0;
          font-size:30px;
          line-height:40px;

          &:not(:last-child) {
            margin-right: 30px;
          }
    
          &:last-child a{
            padding: 0.2rem 1.5rem;
            border-radius: 42px;
            background-color: $main-color;
            border-color: $main-color;
            color:white;
            transition: 0.2s all;
            font-size:30px;
            line-height:40px;
    
            &:hover,
            &:focus,
            &:active {
              padding: 0.2rem 1.5rem;
              border-radius: 42px;
              background-color: $main-active-color !important;
              border-color: $main-active-color !important;
              transition: 0.2s all;

              :after{
                display:none!important;
              }
    
            }
    
          }
           
        }
    
        a {
          color: $main-color;
          text-decoration: none;
          transition: 0.2s all;

          :after{
          display:none;
          }
    
          &:hover {
            text-decoration: none;
            color:$main-active-color;
            transition: 0.2s all;
          }
        }
      }
      .header__mobile-nav-inner {
        &-back {
          position: absolute;
          top: 50%;
          left: 20px;
          transform: translateY(-50%);

          &::after {
            content: url(../img/icons/arrow-big-left.svg);
            display: block;
            clear: both;
          }
        }
        &.active {
          left: 0;
          z-index: 1;
        }
      }
    }
  }

  & .logo {
    position: relative;

    &-desktop {

      &-small {
        display: none;
      }
      &-big {
        display: block;
      }

      @media (max-width: 1199px) {
        &-small {
          max-height:40px;
          display: block;
        }

        &-big {
          display: block;
        }
      }
    }
  }

  @media (max-width: 991px) {
    min-height: 50px;
  }
  ul {
    margin-bottom:0;
    align-items: center;
    justify-content:center;
    li {
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content:center;
      &:not(:last-child) {
        margin-right: 30px;
      }

      &:last-child a{
        padding: 0.1rem 1.5rem;
        border-radius: 42px;
        background-color: $main-color;
        border-color: $main-color;
        color:white;
        transition: 0.2s all;

        &:hover,
        &:focus,
        &:active {
          padding: 0.1rem 1.5rem;
          border-radius: 42px;
          background-color: $main-active-color !important;
          border-color: $main-active-color !important;
          transition: 0.2s all;

        }
        &:after{
          display:none!important;
        }


      }
       
      :after{
        content: '';
        display: block;
        clear: both;
        position: absolute;
        width: 0px;
        height: 0px;
        background-color: $main-color;
        border-radius: 0;
        transition: 0.2s all;

        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
      }


      &.current_page_item.page_item a{
        &::after {
          content: '';
          display: block;
          clear: both;
          position: absolute;
          width: 100%;
          height: 1px;
          background-color: $main-color;
          border-radius: 0;
          transition: 0.2s all;

          bottom: 0px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    a {
      color: $main-color;
      text-decoration: none;

      &:hover {
        text-decoration: none;
        &::after {
          content: '';
          display: block;
          clear: both;
          position: absolute;
          width: 100%;
          height: 1px;
          background-color: $main-color;
          border-radius: 0;

          bottom: 0px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
}

.header-contact{
      width:100%;
      background-color:$main-color;
      height:59px;
      // margin-top:10px;
      color:#fff;

      @media (max-width: 451px) {
        height:69px;
      }

      .mh-59{
        min-height:58px;
        align-items: center;
        justify-content: center;

        @media (max-width: 451px) {
          flex-direction: column;
          min-height:68px;
          width:100%;
          align-items: start!important;
          justify-content: left!important;
        }
        
      }
      
      &__mobile{
        margin-top:0px;
        color:#fff;
        }

      .icon-kontakt{
        padding:10px 5px;
        align-self: flex-start;
      }
      

      a{
        color:#fff;
      }

}