@import './variables';

.main-section {
  &-wrapper {
    background-color: #005a46;
    min-height: calc(100vh - 60px);
    margin-top: 60px;

    @media (min-height: 950px) {
      min-height: 950px;
    }

    @media (max-width: 767px) {
      min-height: calc(100vh - 118px);
      position: relative;
      overflow: hidden;
    }

    @media (max-width: 450px) {
      min-height: calc(100vh - 128px);
    }
  }

  &__bg-mask {
    position: absolute;
    min-height: calc(100vh - 60px);
    width: 100%;

    background-image: url('../img/image-before-slider.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right top;

    @media (min-height: 950px) {
      min-height: 950px;
    }

    @media (max-width: 767px) {
      width: 150%;

      background-size: contain;
      background-repeat: no-repeat;
      background-position: right 238px bottom 68px;
      // background-position: bottom 97px;
    }

    @media (max-width: 500px) {
      background-position: right 160px bottom 68px;
    }
  }

  &__bg-line {
    position: absolute;
    bottom: 0;
    min-height: calc(73vh - 60px);
    width: 100%;
    background-image: url(../img/main-section-line.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right bottom;

    @media (min-height: 950px) {
      min-height: 950px;
    }

    @media (min-width: 1900px) {
      top:10%;
      background-position: right bottom;
    }

    @media (max-width: 767px) {
      background-position: right bottom;
    }

    @media (max-width: 500px) {
    }
  }

  &__carousel-arrows {
    position: absolute;
    top: 55%;
    width: 100%;
    display: flex;
    justify-content: space-between;

    &-prev {
      width: 45px;
    }
    &-next {
      position: absolute;
      right: 15px;
    }
  }

  &__text-carousel {
    position: absolute;
    top: 190px;
    max-width: 565px;

    @media (max-width: 767px) {
      top: 20vh;
      padding-left: 15px;
      padding-right: 15px;
    }

    &-title {
      max-width: 565px;
      color: #fff;
      font-size: 54px;
      line-height: 1.2;

      @media (max-width: 767px) {
        font-size: 34px;
      }
    }
  }

  &__image-carousel {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 0;

    @media (max-width: 767px) {
      bottom: 0px;
    }

    img {
      // max-height: 650px;
      // object-fit: cover;
      // object-position: bottom;
    }
  }
}
.procedures__slides {
  .procedures__slide-bottom-nav {
    bottom: -100px;
    justify-content: center;
  }

  .procedures__slide-bottom .procedures__slide-text-content {
    left: unset;
    bottom: 0px;
    max-width: 50%;
    background-color: transparent;

    @media (max-width: 991px) {
      max-width: 100%;
      bottom: unset;
    }
  }

  .procedures__slide-bottom-arrows {
    margin-right: 0;
  }
}
.procedures {
  padding-top: 150px;
  background-color: #d5e9e2;

  @media (max-width: 991px) {
    padding-top: 0px;
    padding-bottom: 45px;
  }

  &__category {
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
  }
  & h3 {
    margin-bottom: 25px;
  }

  .owl-dots {
    display: none;
  }
  &__list {
    margin-top: 120px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
  }
  &__item {
    margin: 30px;
    &-icon {
      margin-bottom: 35px;
    }
  }
  &__slide {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 1500px;
    margin: 0 auto -100px auto;
  }
  &__slide-text-content {
    padding-top: 74px;
    padding-bottom: 60px;
    padding-left: 60px;
    padding-right: 60px;
    background-color: #fff;

    z-index: 5;

    @media (max-width: 991px) {
      padding-top: 5px;
      padding-left: 20px;
      padding-right: 20px;

      max-width: unset;
    }
  }
  &__slide-top {
    position: relative;

    &-img {
      @media (max-width: 991px) {
        width: 100%;
      }
    }

    .procedures__slide-text-content {
      position: absolute;
      right: 0;
      bottom: 0;
      margin-left: auto;
      max-width: 735px;
      width: 100%;

      @media (max-width: 991px) {
        position: static;
        max-width: unset;
      }
    }

    @media (max-width: 991px) {
      flex-direction: column;
    }
  }

  &__slide-bottom {
    position: relative;

    .procedures__slide-text-content {
      position: absolute;
      left: 100px;
      bottom: 100px;
      margin-left: auto;
      max-width: 735px;
      width: 100%;

      @media (max-width: 991px) {
        position: static;
        max-width: unset;
      }
    }
    &-counter {
      position: absolute;
      right: 42px;
      bottom: 24px;
    }
    &-img {
      margin-left: auto;
      width: 100%;
      min-height: 500px;

      @media (max-width: 991px) {
        width: 100%;
        min-height: 300px;
      }
    }
    &-nav {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: absolute;
      bottom: 0;
      width: 100%;
      min-height: 100px;

      @media (max-width: 991px) {
        // display: none;
        position: static;
        justify-content: center;
      }
    }

    &-arrows {
      display: flex;
      margin-right: 30px;
      color: $main-color;
      font-size: 35px;

      @media (max-width: 991px) {
        margin-right: 0px;
      }
    }

    &-carousel {
      &-text {
        .owl-stage {
          display: flex;
        }
      }
      &-img {
        &-container {
          width: 100%;
          max-width: 50%;
          background-color: transparent;

          @media (max-width: 991px) {
            max-width: unset;
          }
          .owl-dots {
            display: none;
          }
        }
      }
    }

    @media (max-width: 991px) {
      flex-direction: column-reverse;
    }
  }
}

.bg-pfeil {
  margin: 260px 0 100px 0;
  background: url('../img/bg-pfeil.svg') no-repeat;
  background-size: 80% 100%;
  background-position: left bottom;
  min-height: 600px;
  display: flex;
  align-items: center;

  @media (max-width: 991px) {
    font-size: 26px;
    line-height: 36px;
  }

  & h3 {
    font-size: 34px;
    line-height: 64px;
    font-weight: bold;

    @media (max-width: 991px) {
      font-size: 26px;
      line-height: 36px;
    }
  }

  @media (max-width: 1191px) {
    min-height: 400px;
    margin: 150px 0 100px 0;
    background: url('../img/bg-pfeil.svg') no-repeat;
    background-size: 70% 100%;
    background-position: left bottom;
  }
}

.headline-pfeil {
  background: url('../img/pfeil-ueberschrift.svg') no-repeat;
  background-size: 80% 100%;
  background-position: left bottom;
  display: flex;
  align-items: center;
  padding: 2% 5% 0 5%;
  margin: 0;

  @media (max-width: 991px) {
    margin: 20% 0 0 0;
    padding: 10% 5% 0 5%;
  }

  &__abstand {
    padding-top: 23%;
    @media (max-width: 991px) {
      padding-top: 5%;
    }
  }
}

.spaces-s {
  padding: 13% 0 10% 0;
}

.space-bottom {
  padding-bottom: 300px;
  @media (max-width: 991px) {
    padding-bottom: 100px;
  }
}

.bg-kontakt-placeholder {
  min-height: 650px;
  background: url('../img/bg-gray.svg') no-repeat;
  background-color: #d5e9e2;
  background-size: cover;
  background-position: right top;

  @media (max-width: 991px) {
    min-height: 750px;
    // margin-top: -750px;
    background-position: center top;
    background-size: cover;
  }
}

.bg-grey {
  background-color: #ecedf0;
  margin-top: -100px;
  padding-bottom: 160px;
  @media (max-width: 991px) {
    margin-top: 0px;
    padding-bottom: 99px;
  }
}

.kontakt-section {
  background-color: #ecedf0;

  @media (max-width: 991px) {
    margin-top: -350px;
  }
}

.kontakt-feld {
  margin-top: -550px;

  @media (max-width: 991px) {
    margin-top: -200px;
  }
}

.group-label {
  font-size: 16px;
  line-height: 32px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
}

.input-group {
  max-width: 500px;
  width: 100%;
  margin-bottom: 30px;

  @media (max-width: 991px) {
    margin-bottom: 60px;
  }
}
.input {
  height: 36px;
  margin-top: 20px;
  margin-right: 10px;
  max-width: 235px;
  flex-grow: 1;

  padding-left: 19px;
  padding-top: 10px;
  padding-bottom: 10px;

  background-color: #fff;
  border-radius: 50px;
  border: none;
  transition: 0.2s ease-in-out;

  &:focus {
    outline: none;
    border: 1px solid $main-color;
  }
  &:not(:last-of-type) {
    margin-right: 15px;
  }
  &::placeholder {
    font-size: 12px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    color: $main-color;
  }

  &.wide {
    max-width: 343px;
  }

  &.small {
    max-width: 147px;
  }
}

.textarea {
  margin-top: 20px;
  flex-grow: 1;

  padding-left: 19px;
  padding-top: 19px;
  padding-bottom: 10px;

  background-color: #fff;
  border-radius: 20px;
  border: none;
  transition: 0.2s ease-in-out;

  &:focus {
    outline: none;
    border: 1px solid $main-color;
  }

  &::placeholder {
    font-size: 12px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    color: $main-color;
  }

  @media (max-width: 991px) {
    max-width: 343px;
  }
}

.icon-kontakt-box {
  width: 30px;
  display: flex;
  justify-content: center;
}

.icon-kontakt {
  padding: 10px 10px;
  align-self: flex-start;
}

.absolute-input {
  position: relative;
  cursor: pointer;
}

.wpcf7-list-item-label {
  margin-left: 40px;
  display: inline-block;
}

input[type='checkbox'] {
  position: absolute;
  visibility: hidden;
  width: 21px;
  height: 21px;
  top: 0;
  left: 0;
}

input[type='checkbox'] + span:before {
  margin-top: 7px;
  display: block;
  position: absolute;
  content: '';
  border-radius: 0;
  height: 21px;
  width: 21px;
  top: 0px;
  left: 0px;
  border-radius: 50%;
  border: 1px solid $main-color;
}

input[type='checkbox'] + span:after {
  border-color: $main-color;
  background-color: $main-color;
  top: 0;
  left: 0;
  visibility: hidden;
  line-height: 30px;
}

input[type='checkbox']:checked + span:before {
  background-color: $main-color;
}

input[type='checkbox']:checked + span:after {
  visibility: visible;
}

.wpcf7-not-valid {
  border: 1px solid red !important;
}
.wpcf7-not-valid-tip {
  display: none !important;
}

.contentContainer {
  min-height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-self: center;
}

h1.vierovier {
  font-size: 10rem;
  margin: 5% 0;
}

.page-container {
  padding: 200px 0 0 0;
  min-height: 80vh;
  @media (max-width: 991px) {
    padding: 100px 0 0 0;
    min-height: 50vh;
  }
}

.singleHeadImage {
  min-height: 500px;
  margin-top: 100px;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: 991px) {
    margin-top: 0px;
  }
}
