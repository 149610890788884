@import './variables.scss';

@font-face {
  font-family: 'Aeonik';
  src: url('../fonts/Aeonik-Regular.woff2') format('woff2'),
    url('../fonts/Aeonik-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html {
  box-sizing: border-box;
  height: 100%;
  scroll-behavior: smooth;
}

body {
  scroll-behavior: smooth;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  font-family: Aeonik, serif;
  height: 100%;
  font-size: 16px;
  line-height:30px;
  color:$main-color;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*='col-'] {
    padding-right: 0;
    padding-left: 0;
  }
}
ul.fp {
  list-style-type: none;
  padding:0 0 0 13px;
}

ul.fp li:before{
  content: "·";
  font-size: 20px;
  margin-left: -10px;
  padding-right: 2px;
  text-decoration: none !important;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.container-fluid {
  max-width: 1440px;
}
.mobile-auto-height {
  @media (max-width: 991px) {
    min-height: unset;
  }
}
.visually-hidden {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}

.filter-gray {
  filter: grayscale(1) brightness(0.8);
  transition: all 0.2s linear;
  @media (max-width: 991px) {
    display:none;
  }
}

.lh-1 {
  line-height: 1;
}
hr {
  border-top: 1px solid $main-active-color;
  padding:0;
  margin:0;
}

.mb-6{
  margin:0;

  @media (max-width: 991px) {
    margin: 0 0 115px 0;
  }

}

a {
  color: $main-color;

  &:hover {
    color: $main-active-color;
  }
}
h1,
.h-1 {
  font-size: 54px;
  line-height:65px;
}

h2,
.h-2 {
  font-size: 32px;
  line-height: 44px;
}

h3,
.h-3 {
  font-size: 32px;
}

h3.kontakt,
.h-3.kontakt {
  font-size: 42px;
  line-height:64px;
  padding:10px 30px;
}

h3.big,
.h-3.big {
  font-size: 54px;
  line-height:65px;

  &__pfeil {
    padding: 0 0 0 35%;
    max-width: 400px;
    color:$main-color;

    @media (max-width: 450px) {
          padding: 20% 0 0 20%;
    }

    @media (min-width: 991px) and (max-width: 450px){
          padding: 15% 0 0 30%;
    }
  }
}


h5,
.h-5 {
  font-size: 21px;
}
@media (max-width: 991px) {
  h1,
  .h-1 {
    font-size: 36px;
  }

  h2,
  .h-2 {
    font-size: 26px;
  }

  h3,
  .h-3 {
    font-size: 32px;
    line-height:44px;
  }

  h4,
  .h-4 {
    font-size: 18px;
  }
  h5,
  .h-5 {
    font-size: 18px;
  }
}

.nav-mobile-open {
  max-height: 100vh;
  overflow: hidden;
}


/* #region bootstrap */

.btn {
  padding: 0.1rem 1rem;
  border-radius: 42px;

  &:focus,
  &.focus {
    box-shadow: none;
  }
}

input.wpcf7-form-control.wpcf7-submit{
  padding: 0.3rem 1.5rem;
  border-radius: 42px;
  color:#fff;
  min-width:100px;
  font-size:16px;
  background-color: $main-active-color;
  border:1px solid $main-active-color;
  transition: 0.2s ease-in-out;

  &:hover,
  &:focus,
  &:active {
    background-color: $main-color!important;
    border:1px solid $main-color!important;
  }
}

.btn-big {
  padding: 0.3rem 1.5rem;
  border-radius: 42px;
  border:1px solid $main-active-color;
  transition: 0.2s ease-in-out;

  &:focus,
  &.focus {
    box-shadow: none;
  }
}

.btn-primary {
  background-color: $main-color;
  border-color: $main-color;

  &:hover,
  &:focus,
  &:active {
    background-color: $main-active-color !important;
    border-color: $main-active-color !important;
  }
}

.btn-secondary {
  background-color: $main-active-color;
  border-color: $main-active-color;

  &:hover {
    background-color: transparent;
    border-color: $main-active-color !important;
    color: $main-active-color !important;
  }
  &:active {
    background-color: transparent !important;
    border-color: $main-active-color !important;
    color: $main-active-color !important;
  }

  &:disabled {
    &:hover,
    &:focus,
    &:active {
      color: #fff !important;
      background-color: #6c757d !important;
      border-color: #6c757d !important;
      cursor: not-allowed;
    }
  }
}
.btn-outline-secondary {
  color: $secondary-color;
  border-color: $secondary-color;

  &:hover {
    background-color: $secondary-color;
    border-color: $main-color;
    color: $main-color;
  }
}
.btn-outline-primary {
  color: $main-color;
  border-color: $main-color;

  &:hover {
    background-color: $secondary-color;
    border-color: $main-active-color;
    color: $main-active-color;
  }
}

.text-dark {
  color: #000 !important;
}
.text-primary {
  color: $main-color !important;
}
.text-shadow {
  color: $shadow-text-color;
  letter-spacing: 2.4px;
}
.text-accent {
  color: $main-active-color;
}

.text-small {
  font-size: 12px;
  line-height:16px;
}

.nav-tabs {
  border-bottom: none;
  margin-bottom: 30px;

  .nav-item {
    &:not(:last-of-type) {
      margin-right: 25px;
    }
  }
  .nav-link {
    padding-right: 0;
    padding-left: 0;
    border: none;
    color: $main-color;

    &:hover {
      color: $main-active-color;
    }

    &.active {
      border: none;
      border-bottom: 2px solid $main-color;
      color: $main-color;
    }
  }
}
/* #endregion */

.full-screen {
  height: 100% !important;
  max-height: 870px;

  @media (max-width: 991px) {
    height: auto !important;
  }
}

.full-screen-desktop {
  min-height: 100vh;
  max-height: 870px;
  min-height: calc(100vh - 90px);

  @media (max-width: 991px) {
    padding-top: 80px;
    padding-bottom: 80px;
    min-height: unset;
  }
}

.card {
  border-left: none;
  border-right: none;
  border-color: #c4ebdb;

  &:not(:last-of-type) {
    border-bottom: none;
  }
  &-header {
    padding-left: 0;
    padding-right: 0;
    border: none;
    background-color: #fff;

    display: flex;
    justify-content: space-between;
    align-items: center;

    &-toggler {
      background-color: #fff;
      border: none;
    }

    .btn,
    .btn-link {
      padding-left: 0;
      color: $main-color;
    }
  }

  &-body {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.spaces-primary{
    padding: 215px 0 215px 0; 
    color:$main-color;
    font-family: 'Aeonik';

    @media (max-width: 991px) {
      padding: 115px 0 115px 0; 
    }

    & p{
      &.big{
        font-size: 34px;
        line-height:59px;
        @media (max-width: 991px) {
          font-size: 26px;
          line-height:38px;
        }
      }
      &.small{
        font-size: 22px;
        line-height:29px;
        letter-spacing: 1.32px;
        text-transform: uppercase;
        @media (max-width: 991px) {
          font-size: 22px;
          line-height:26px;
        }
      }

    }
}

.big{
  font-size: 34px;
  line-height:59px;
  max-width:852px;
  margin:auto;
  @media (max-width: 991px) {
    font-size: 26px;
    line-height:38px;
    padding-bottom: 100px;
  }
}

#map {
  height: 100%;
  width:100%;
  max-width:100%;
  margin: 0;
  padding: 0;
  outline:0;
}

.front-map{
  min-height: 556px;
}
