//Colors

$main-color: #005A46;
$main-active-color: #03c994;

$footer-link-color: #B3EFDF;

$main-footer-color: #005A46;

$secondary-color: #ffffff;

$shadow-text-color: #b2b2b2;
